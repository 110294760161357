<template>
	<div id="bloc" class="after-inner">
		<article class="wrapper" id="detail">
			<section id="main">
				<!-- 返回按钮 -->
				<!-- <div class="wrap">
					<div class="con">
						<div class="other">
							<a class="return" @click="returnJobList()"><i class="el-icon-back"></i> 返回列表</a>
						</div>
					</div>
				</div> -->
				<!-- <div class="wrap">
					<el-button @click="returnJobList()"><i class="el-icon-back"></i> 返回列表</el-button>
				</div> -->
				<div class="main-content">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span class="box-card-title">{{job.name}}</span>
						</div>
						<!-- <div>{{job.createTime}}&nbsp;&nbsp;发布</div>
						<div><i class="el-icon-user"></i>&nbsp;&nbsp;{{job.department}}</div>
						<div><i class="el-icon-location-information"></i>&nbsp;&nbsp;{{job.city}}</div> -->
						<p v-if="language=='cn'">{{job.createTime}}&nbsp;&nbsp;<!-- 发布 -->{{$t('jobDetails:Posted on')}}</p>
						<p v-else-if="language=='en'">{{$t('jobDetails:Posted on')}} {{job.createTime}}&nbsp;&nbsp;<!-- 发布 --></p>
						<p><i class="el-icon-user"></i>&nbsp;&nbsp;{{job.department}}</p>
						<p><i class="el-icon-location-information"></i>&nbsp;&nbsp;{{job.city}}</p>
						<div class="hr"></div>

						<div>
							<h1><!-- 工作职责 -->{{$t('jobDetails.Responsibilities')}}</h1>
							<!-- <p class="title-item">Alpsentek GmbH 正在寻找一位经验丰富的布局工程师，他可以利用广泛的技能和专业知识来开发和支持我们当前和未来的图像传感器产品。
							</p>
							<p class="title-item">
								候选人将在一个国际团队中工作，领导芯片级和块级物理布局设计和验证。与设计和系统工程以及制造支持的密切合作对于定义和开发正确的产品以及提供硅后支持（包括芯片启动和表征）是必要的。
							</p>
							<p class="title-item">该职位需要以结果为导向的团队成员，专注于实现产品技术要求和目标上市时间。</p> -->
							<div class="title-item" v-html="job.duty"></div>
						</div>

						<div class="hr"></div>
						<div>
							<h1><!-- 工作要求 -->{{$t('jobDetails.Qualifications')}}</h1>
							<div class="title-item" v-html="job.requirements"></div>
							<!-- <h2 class="title-message">教育：</h2>
							<p class="title-item">电气工程学士学位（或类似学位）。</p>
							<h2 class="title-message">经验：</h2>
							<p class="title-item">块级和芯片级物理布局设计和验证。至少5年优先。</p>
							<p class="title-item">代工设计套件和流片工艺的管理。</p>
							<p class="title-item">设计文档的作者和随后的布局设计和代工厂掩膜流片审查。</p>
							<p class="title-item">亲身体验 65/55nm 和 45/40 nm CIS（CMOS 图像传感器）工艺。</p>
							<p class="title-item">熟练使用行业标准的Cadence设计和验证工具，包括版本控制软件工具（ClioSoft SOS）。</p>

							<h2 class="title-message">知识、技能、能力：</h2>

							<p class="title-item">出色的沟通技巧（口头、书面、演示）——成为一个积极主动、谦虚的倾听者，能够接收、处理团队成员的反馈并采取行动。</p>
							<p class="title-item">需要英语，其他语言优先</p>
							<p class="title-item">Cadence SKILL 的编程语言技能和图像传感器行业的知识是加分项。</p> -->

							<div class="hr"></div>
							<div>
								<h1><!-- 投递方式 -->{{$t('jobDetails.How to apply')}}</h1>
								<p class="title-item"><!-- 请将职位名称/编号、姓名作为邮件标题，投递简历至我司招聘邮箱。 -->{{$t('jobDetails.message5')}}</p>
							</div>

						</div>
						<div style="text-align: center;margin-top: 80px;">
							<div class="button" @click="toEmail()"><!-- 申请 -->{{$t('jobDetails.APPLY')}}</div>
						</div>
					</el-card>
				</div>

			</section>
			<section id="btm"></section>
		</article>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			// title: "新闻列表", // set a title
			link: [{
				// set link
				rel: "asstes",
				href: "www.baidu.com",
			}, ],
		},
		data() {
			return {
				job: {},
				code: ''
			};
		},
		created() {
			window.document.title = this.$t('joinus.title')
			this.code = this.$route.query.code
			console.log(this.code)
		},
		computed:{
			language(){
				return this.$store.state.Language
			}
		},
		mounted() {
			this.getTopStar()
		},
		watch:{
			language(newVal,oldVal){
				window.document.title = this.$t('joinus.title')
				this.getTopStar()
			}
		},
		methods: {
			getTopStar(){ //获取招聘信息
				let language = this.$store.state.Language
				this.$http
					.get(
						`/recruit/list?language=${language}&code=${this.code}`
					)
					.then((res) => {
						this.job = res.rows[0]
						console.log(this.job)
					});
			},
			toEmail(){
                window.location.href = 'mailto:talent@alpsentek.com?subject='+this.job.name
            }
		},
	};
</script>

<style scoped lang="scss">
	#bloc{
		background-color: #f5f5f7;
	}
	
	.bqxx {
		text-align: center;
		// margin-top: 20px;
	}

	.bqxx-1 {
		margin: 5px;
		padding: 5px;
		border-radius: 8px;
		font-weight: 700;
		color: rgb(76, 76, 76);
		border: 2px solid rgb(221, 229, 240);
		background-color: rgb(221, 229, 240);
	}

	@media screen and (max-width: 1366px) {

		a,
		span,
		li,
		b,
		i,
		label,
		p,
		strong,
		div,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		small,
		em,
		li,
		pre,
		form,
		fieldset,
		legend,
		button,
		input,
		textarea,
		th,
		td {
			font-size: 14px;
			font-family: word;
		}
	}

	body {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	body {
		font: 12px "PingFang HK", "Microsoft YaHei", "Arial";
		color: #000;
		line-height: 1.5;
		overflow-x: hidden;
	}

	#detail {
		// padding-top: 0.9rem;
		// background-color: #f5f5f6;
	}

	.after-inner .wrapper {
		// padding-top: 0.9rem;
	}

	.wrapper {
		overflow: hidden;
		position: relative;
		z-index: 2;
	}

	#detail #main {
		position: relative;


		.wrap {
			width: 90%;
			margin: 0px auto;
			margin-top: 80px;
		}

	}

	#detail #main .wrap {
		position: relative;
		z-index: 5;
	}

	#detail #main .main-content {
		width: 66.6667%;
		margin: 0px auto;
		margin-top: 90px;
		margin-bottom: 10px;

		.hr {
			height: 2px;
			background-color: #d8d8d8;
			margin: 5px 0px;
		}

		.box-card-title {
			color: #000;
			font-size: 24px;
			font-weight: 800;
			font-family: 'Source Han Sans Light','Source Han Sans', 'Source Han Sans Unicode', Geneva, Verdana, sans-serif !important;
		}

		.title-message {
			text-indent: 5px;
		}

		.title-item {
			text-indent: 10px;
			color: #444444;
			font-weight: 300;
		}

		.button {
			margin: 0px auto;
			height: 40px;
			width: 155px;
			line-height: 40px;
			background-color: #2350c7;
			color: #ffffff;
			border-radius: 5px;
			font-size: 18px;
			border: 2px solid #2350c7;
			box-sizing: border-box;
			transition: 0.5s;
		}
		
		.button:hover{
			background-color: rgb(255, 255, 255);
			color: rgb(35, 80, 199);
			cursor: pointer;
		}
	}

	@media screen and (max-width: 996px) {
		#detail #main .main-content {
			width: 83.3%;
		}
	}

	#detail #main .wrap .other {
		height: 6vw;
		position: relative;
		// width: 64%;
		max-width: 860px;
		// margin: auto;
	}

	#detail #main .wrap .other a {
		box-shadow: 0px 0px 4px #ccc;
		border-radius: 8px;
		text-align: end;
	}

	#detail #main .wrap .other a:hover {
		background: rgb(34, 81, 197);
		color: #fff;
	}

	#detail #main .wrap .other .return {
		background-color: rgb(255, 255, 255);
		padding: 10px 26px;
		text-align: center;
		position: absolute;
		display: inline-block;
		bottom: 20px;
		left: 0;
		color: black;
	}

	a {
		cursor: pointer;
	}

	#detail #main .wrap .con {
		width: 64%;
		max-width: 1200px;
		margin: auto;
		background-color: #fff;
		padding: 2rem 1rem;
		margin-top: 6vw;
		border-radius: 12px;
		margin-bottom: 6vw;
	}

	#detail #main .wrap .con .title {
		position: relative;
	}

	#detail #main .wrap .con .title .date {
		height: 0.48rem;
		line-height: 0.48rem;
		margin-bottom: 0.24rem;
		display: flex;
		justify-content: center;
	}

	@media screen and (max-width: 1280px) {
		.f-24 {
			font-size: 16px;
		}
	}

	@media screen and (max-width: 1366px) {
		.f-24 {
			font-size: 17px;
		}
	}

	@media screen and (max-width: 1500px) {
		.f-24 {
			font-size: 22px;
		}
	}

	#detail #main .wrap .con .title {
		position: relative;
	}

	#detail #main .wrap .con .title .line {
		margin-top: 0.5rem;
	}
</style>
